// See the file environment.ts for details.
export const environment = Object.seal({
  termsOfUseVersion: "May 4, 2022",
  trustedDomains: [
    "blog.services.dynatrace.com",
    "docs.services.dynatrace.com",
    "dynatrace.com",
    "www.dynatrace.com"
  ]
});
